<template>
    <main-section-primary dark style="background-image: url(/images/main/introduction-bg.jpg)">
        <template #titHead>I</template>
        <template #tit>NTRODUCTION</template>
        <template #titAdd>판심 법무법인 소개</template>
        <v-row no-gutters>
            <v-col v-for="(item, index) in info" :key="index" cols="12" md="4" class="introduction-item">
                <div data-aos="fade-up" :data-aos-delay="200*(index+1)" class="w-100 d-flex flex-column align-center text-center px-16px py-20px py-md-0">
                    <v-img :src="item.icon" :width="$vuetify.breakpoint.mdAndUp ? 80 : 60" contain :aspect-ratio="1 / 1" class="mb-24px mb-md-48px"/>
                    <u-tit-default class="primary--text font-primary">{{item.title}}</u-tit-default>
                    <u-txt-default class="line-height-15 white--text mt-8px mt-md-16px">
                        <p v-html="item.txt"></p>
                    </u-txt-default>
                </div>
            </v-col>
        </v-row>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    components: {
        MainSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    data() {
        return {
            info: [
                {
                    title: "Secret",
                    txt: "당신이 오신 것 마저 <br class='d-none d-lg-block' />비밀로 유지됩니다.",
                    icon: "/images/main/introduction-icon.svg",
                },
                {
                    title: "Divorce TF team",
                    txt: "내가 이혼을 하게된다면 <br class='d-none d-lg-block' />어떤 변호사를 선택할지 기준을 세웠습니다.",
                    icon: "/images/main/introduction-icon2.svg",
                },
                {
                    title: "High quality legal service",
                    txt: "이혼·가사 사건은 물론, 파생되는 민·형사 사건까지 <br class='d-none d-lg-block' />원스톱서비스를 제공합니다.",
                    icon: "/images/main/introduction-icon3.svg",
                },
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
.row{
    >.introduction-item{
        border-top: 1px solid rgba(255, 255, 255, .15);
        &:last-child{
            border-bottom: 1px solid rgba(255, 255, 255, .15);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row{
        >.introduction-item{
            border-top: 0;
            border-left: 1px solid rgba(255, 255, 255, .15);
            &:last-child{
                border-bottom: 0;
                border-right: 1px solid rgba(255, 255, 255, .15);
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>

<template>
    <u-footer-basic>
        <u-footer-body-basic>
            <template #left>
                <logo-secondary>판심 법무법인</logo-secondary>
            </template>
            <template #right>
                <v-row>
                    <v-col cols="12">
                        <footer-info />
                    </v-col>                            
                    <v-col cols="12">
                        <u-txt-default class="txt--light line-height-17">Copyright 2023. 판심 법무법인 All Rights Reserved.</u-txt-default>
                    </v-col>
                </v-row>
            </template>
        </u-footer-body-basic>
    </u-footer-basic>
</template>

<script>
import LogoSecondary from "@/components/publish/parents/headers/logo-secondary.vue";

import UFooterBasic from "@/sets/styles/footers/u-footer-basic.vue";
import UFooterBodyBasic from "@/sets/styles/footers/u-footer-body-basic.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import FooterInfo from "@/components/publish/styles/footers/footer-info.vue";

export default {
    components: {
        LogoSecondary,

        UFooterBasic,
        UFooterBodyBasic,

        UTxtDefault,
        UTxtMedium,
        FooterInfo,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
    padding-bottom: 36px;
}
@media (min-width: 768px) {
    .footer {
        padding-bottom: 0;
    }
}
</style>

<template>
    <main-section-primary style="background-image: url(/images/main/solution-bg.jpg)">
        <v-row>
            <v-col cols="12" lg="5">
                <tit-wrap-primary dark>
                    <template #titHead>S</template>
                    <template #tit>OLUTION</template>
                    <template #titAdd>단계별 솔루션</template>
                </tit-wrap-primary>
            </v-col>
            <v-col cols="12" lg="7">
                <div class="pl-lg-42px">
                    <v-row>
                        <v-col v-for="(item, index) in info" :key="index" cols="12">
                            <dialog-primary :btnAttrs="{ color: 'grey lighten-3' }" dark max-width="1440">
                                <template #activator="{ attrs, on }">
                                    <v-card v-bind="attrs" v-on="on" tile flat class="solution-item image-card plus-btn-card" :class="index % 2 !== 0 ? 'ml-20px ml-md-80px' : 'mr-20px mr-md-80px'" :style="'background-image: url(' + item.image + ');'">
                                        <div class="solution-item__inner pa-20px pa-md-30px pa-lg-48px">
                                            <v-row align="center" class="row--xs">
                                                <v-col>
                                                    <u-tit-default data-aos="fade-up" class="tit--sm">{{ item.title }}</u-tit-default>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div data-aos="fade-up" data-aos-delay="200">
                                                        <icon-plus class="grey--text" />
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </template>
                                <u-txt-default class="txt--dark">
                                    <v-row>
                                        <v-col cols="12" class="d-none d-lg-block">
                                            <v-row>
                                                <v-col cols="12" lg="7">
                                                    <div class="white pa-16px text-center">
                                                        <p class="font-weight-medium grey--text text-darken-4">상황</p>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" lg="5">
                                                    <div class="white pa-16px text-center">
                                                        <p class="font-weight-medium grey--text text-darken-4">프로그램</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-for="item in item.infoDetail" :key="item.title" cols="12">
                                            <v-row no-gutters class="mx-md-n12px">
                                                <v-col cols="12" lg="7" class="px-md-12px">
                                                    <v-row no-gutters class="h-100" :class="$vuetify.breakpoint.lgAndUp ? '' : 'border-b'">
                                                        <v-col cols="12" lg="5">
                                                            <div class="h-100 white--text" :class="item.color">
                                                                <div class="h-100 pa-16px pa-md-30px pa-lg-48px">
                                                                    <v-row class="row--xs flex-lg-column h-100">
                                                                        <v-col cols="12" lg="">
                                                                            <u-tit-default class="tit--sm line-height-15">
                                                                                {{ item.title }}
                                                                            </u-tit-default>
                                                                        </v-col>
                                                                        <v-col cols="auto">
                                                                            <p class="line-height-15">
                                                                                {{ item.txt }}
                                                                            </p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" lg="7">
                                                            <div class="h-100 white">
                                                                <div class="pa-16px pa-md-30px pa-lg-48px">
                                                                    <u-tit-default class="tit--sm font-weight-medium font-primary">
                                                                        {{ item.solution }}
                                                                    </u-tit-default>
                                                                    <p v-for="detail in item.solutionTxt" :key="detail" class="grey--text text--lighten-1 line-height-15 mt-12px mt-md-24px">
                                                                        {{ detail }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" lg="5" class="px-md-12px">
                                                    <div class="h-100 white pa-16px pa-md-30px pa-lg-48px">
                                                        <v-row>
                                                            <v-col v-for="item in item.program" :key="item.title" cols="12">
                                                                <u-tit-default class="tit--sm font-weight-medium font-primary">
                                                                    {{ item.title }}
                                                                </u-tit-default>
                                                                <v-row class="row--sm pt-20px pt-md-24px">
                                                                    <template v-if="!item.txtDetail">
                                                                        <v-col v-for="(detail, index) in item.txt" :key="index" cols="12">
                                                                            <v-row no-gutters>
                                                                                <v-col cols="auto">
                                                                                    <u-tit-default class="tit--sm line-height-1 font-weight-semibold primary--text w-40px"> 0{{ index + 1 }} </u-tit-default>
                                                                                </v-col>
                                                                                <v-col>
                                                                                    <p class="grey--text text--lighten-1 line-height-15">
                                                                                        {{ detail }}
                                                                                    </p>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </template>
                                                                    <template v-else-if="item.txtDetail">
                                                                        <v-col v-for="(item, index) in item.txtDetail" :key="index" cols="12">
                                                                            <v-row no-gutters>
                                                                                <v-col cols="auto">
                                                                                    <u-tit-default class="tit--sm line-height-1 font-weight-semibold primary--text w-40px"> 0{{ index + 1 }} </u-tit-default>
                                                                                </v-col>
                                                                                <v-col>
                                                                                    <p class="grey--text text--darken-4 line-height-15 pb-4px pb-md-8px">
                                                                                        {{ item.title }}
                                                                                    </p>
                                                                                    <txt-dot v-for="(detail, index) in item.txt" :key="index" class="grey--text text--lighten-1" :class="index !== 0 ? 'mt-2px mt-md-4px' : ''">
                                                                                        {{ detail }}
                                                                                    </txt-dot>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </template>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </u-txt-default>
                            </dialog-primary>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import TxtDot from "@/components/publish/styles/typography/txt-dot.vue";
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    components: {
        MainSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        TxtDot,
        DialogPrimary,
        CardPrimary,
        IconPlus,
    },
    data() {
        return {
            info: [
                {
                    title: "결혼생활 상담 단계",
                    image: "/images/main/solution-img.jpg",
                    infoDetail: [
                        {
                            title: "이혼 여부가 고민될 때",
                            txt: "결혼생활 상담 단계",
                            solution: "현재 결혼생활이 불행하다고 느껴지신다면, 본인의 행복을 위해서 전문가의 진단을 받아야 합니다.",
                            solutionTxt: ["판심 법무법인은 금전적 이익을 위해 이혼을 강요하지 않습니다. 오로지 의뢰인의 진정한 행복을 찾아 함께 고민하고 해결책을 찾아가야합니다.", "이혼을 해야하는지 혼자 고민하기보다 전문가와 상담하여 이혼 여부와 시기를 결정해야 합니다.", "이혼은 언제 어떻게 하느냐에 따라 유불 리가 크게 달라집니다. 가장 유리한 이혼 시점을 선택하기 위해서라도 꼭 전문가와 상담한 후 이혼의사를 확정지어야 합니다."],
                            program: [
                                {
                                    title: "1. 판심 법무법인의 결혼생활 점검 프로그램",
                                    txt: ["판심 법무법인은 자체적인 결혼생활 체크리스트 및 상담을 통하여 결혼 생활이 건강하게 유지되고 있는지, 결혼생활에 대한 만족도가 양호한지, 결혼생활에 대한 불만족이 얼마나 누적되어 있는지 함께 고민하고 해결책을 논의합니다.", "이혼사유가 존재하는지, 이혼한다면 어느 시점에 하면 좋은지 양육권, 재산분할 등 법률적 관점에서 명확한 솔루션을 제공합니다."],
                                },
                                {
                                    title: "2. 합의서 작성 프로그램",
                                    txt: ["결혼생활에 불만족하지만, 이혼 결정이 어려우신 분들을 위해 ‘각서’가 아닌, 법률적 용어를 활용한 ‘합의서’를 통해 의뢰인의 결혼생활을 보호하고 장래에 있을 혹시모를 이혼소송과 법률분쟁에 대비합니다."],
                                },
                            ],
                            color: "grey lighten-1",
                        },
                        {
                            title: "이혼하기로 합의하였는데, 협의이혼을 어떻게 진행해야할지 모를 때",
                            txt: "협의이혼 상담",
                            solution: "인생의 중요한 결정은 꼭 전문가와 상담 해야 합니다.",
                            solutionTxt: ["전문가의 조력 없이 배우자와 협의로 이혼을 진행하다가 '위자료'와 '재산분할', ‘양육권’에 관하여 합의를 하지 않거나, 불리하게 합의하는 경우가 발생합니다.", "인생의 중차대한 일을 결정하는데, 향후에 법률적인 문제가 발생하지 않도록 협의단계에서부터 법률전문가의 조력이 필요합니다."],
                            program: [
                                {
                                    title: "협의이혼 진행 프로그램",
                                    txt: ["판심 법무법인은 자체적인 결혼생활 체크리스트 및 상담을 통하여 결혼 생활이 건강하게 유지되고 있는지, 결혼생활에 대한 만족도가 양호한지, 결혼생활에 대한 불만족이 얼마나 누적되어 있는지 함께 고민하고 해결책을논의합니다.", "변호사가 협의과정에서 있을 법률적 쟁점을 설명해준 후 양당사자의 의사가 합치되는 점을 합의서의 형태로 작성합니다.", "협의이혼 신청서와 합의서를 제출한 후 합의사항 이행을확인합니다."],
                                },
                            ],
                            color: "grey lighten-1",
                        },
                    ],
                },
                {
                    title: "이혼상담 및 소송 준비 단계",
                    image: "/images/main/solution-img2.jpg",
                    infoDetail: [
                        {
                            title: "배우자의 폭력, 폭언이 두려우신 경우",
                            txt: "이혼상담 및 소송 준비 단계",
                            solution: "나와 자녀를 지키기 위한 최선의 방법은 최선의 공격입니다.",
                            solutionTxt: ["배우자의 폭력으로 인해 이혼소송 자체를 두려워하는 경우, 또는 이혼 소송이 진행됨에따른 보복성 폭력이 발생할 수 있는 경우가 존재합니다.", "판심 법무법인은 이혼과정에서의 가정폭력대응 프로그램을 통해 신체적·정신적으로 안정된 상태에서 이혼을 차분히 준비할 수 있도록 지원합니다.", "판심 법무법인은 상대방의 폭력의 정도와 의뢰인님의 의사에 따라 ① 형사 고소 또는 ② 접근금지 가처분과 같은 임시 처분 두가지 대응프로그램을 동시에 운영하고 있습니다."],
                            program: [
                                {
                                    title: "판심 법무법인 가정폭력대응 프로그램",
                                    txt: [
                                        "배우자의 폭행, 폭언, 위치추적, 스토킹, 원치않는 성관계, 물건 손괴 사실을 확인합니다. 진단서, 경찰신고내역, 상해사진 등 관련 증거를 확보합니다.",
                                        "상담과 동시에 고소장 또는 임시처분(접근금지가처분, 즉시 양육자 지정신청, 즉시 양육비 및 생활비 지급 신청) 신청서를 작성하여 제출하여 신속하게 의뢰인을 보호합니다.",
                                        "의뢰인과 1:1 변호사 밀착 모니터링을 통해 추가적으로 발생할 수 있는 폭력사태를 방지하고, 그 외 스토킹 등 추가적인 범행이 발생하면 즉각적인 추가 대응을 실시합니다.",
                                    ],
                                },
                            ],
                            color: "grey darken-1",
                        },
                        {
                            title: "배우자가 재산은닉을 시도하는 경우",
                            txt: "이혼상담 및 소송 준비 단계",
                            solution: "이혼을 결심했다면, 먼저 움직여야 승리합니다.",
                            solutionTxt: ["이혼소송에서 승소하여 판결문을 받았으나, 상대방이 재산을 미리 은닉해두거나 처분하여 실제로 판결받은 돈을 지급받지 못하는 경우가 발생할 수 있습니다.", "판심 법무법인은 체계적인 재산확보 프로그램을 운영하고 있어 선제적인 가압류, 가처분을 통해 승소의 밑바탕을 마련합니다."],
                            program: [
                                {
                                    title: "판심 법무법인 재산확보 프로그램",
                                    txt: ["판심 법무법인은 이혼소송에 앞서 은행, 보험사, 채권, 부동산, 보증금, 장래 퇴직금, 연금 등을 일시에 조회하여 상대방의 재산을 파악합니다.", "신속하게 가압류 및 가처분을 통하여 장래에 수령할 재산을 확보합니다.", "판심 법무법인를 찾아오시기 전에 이미 처분한 재산이 있는 경우, 적극적인 사해행위 취소소송을 통해 재산을 돌려받아 확보합니다."],
                                },
                            ],
                            color: "grey darken-1",
                        },
                        {
                            title: "상대가 아이를 양육하고 있는 경우",
                            txt: "이혼상담 및 소송 준비 단계",
                            solution: "‘현재’양육권자가 ‘미래’ 양육권자입니다.",
                            solutionTxt: ["법원은 양육자를 지정함에 있어서 '자녀의 복리', '현재의 양육상태 유지'를 중요한 요소로 고려하므로 ‘현재’ 양육권자가 ‘미래’의 양육권자가 될 확률이 매우 높습니다.", "따라서 양육권을 위해서라면 적극적인 사전처분제도를 활용하여 양육권 분쟁에 대비하여야 합니다."],
                            program: [
                                {
                                    title: "판심 법무법인 유아인도 및 양육환경 조성 프로그램",
                                    txt: ["양육권에 대한 의뢰인의 의사와 자녀의 의사를 파악합니다.", "즉시 유아인도를 청구하며, 필요에 따라 상대방에 대한 접근금지 가처분을 준비합니다.", "과거에 미납된 양육비가 있는 경우, 과거 양육비를 청구하며, 소송중에도 양육비와 생계비를 지급받을 수 있도록 임시양육비 사전처분을 신청합니다."],
                                },
                            ],
                            color: "grey darken-1",
                        },
                        {
                            title: "상간남녀 정보 입수",
                            txt: "이혼상담 및 소송 준비 단계",
                            solution: "상대방의 부정행위, 이혼소송과 함께 반드시 상간자에게도 책임을 물어야 합니다.",
                            solutionTxt: ["간통죄가 폐지된만큼 바람을 핀 배우자와 상간남녀에 대해서는 반드시 상간소송을 통해 그 책임을 물어야 합니다.", "그러나 보통 이혼소송을 진행하게되면, 배우자가 상간남녀의 존재를 숨기는 경우가 많으므로 반드시 법률전문가의 조력을 받아 상간남녀의 정보를 입수하여야 합니다."],
                            program: [
                                {
                                    title: "판심 법무법인 사실조회 시스템",
                                    txt: ["신속하게 사실조회를 통해 상간남녀에 대한 정보를 입수합니다.", "상간남녀의 재산뿐만 아니라 현재 배우자와의 연락상황 등 추가적인 부정행위 증거를 탐지합니다.", "상간남녀의 집 또는 직장에 상간소송 소장을 송달시키며 소송을 시작합니다."],
                                },
                            ],
                            color: "grey darken-1",
                        },
                    ],
                },
                {
                    title: "이혼소송 단계",
                    image: "/images/main/solution-img3.jpg",
                    infoDetail: [
                        {
                            title: "이혼소송 중 생활비 확보",
                            txt: "이혼소송 단계",
                            solution: "이혼소송, 장기전에도 대비하여야 합니다.",
                            solutionTxt: ["이혼소송이 진행되면, 상대방에서 생활비 지급을 멈추는 경우가 많습니다.", "그러나 이혼소송중에도 부부관계는 유지되므로 상대방은 부양료 및 양육비를 지급할 의무가 있습니다.", "이혼소송기간동안 충분한 생활비가 확보되어야 재판과정에서 불리한 위치에 서지 않고 자신있게 싸울 수 있습니다."],
                            program: [
                                {
                                    title: "판심 법무법인 생활보호 프로그램",
                                    txt: ["이혼소송전 부양료 수준 및 양육비 수준 확인하고 관련 증거를 확보합니다.", "부양료사전처분 및 양육비사전처분을 신청합니다.", "사전처분 결정 이후 생활비 및 양육비 지급 모니터링하며, 미지급시 과태료 부과하여 압박합니다."],
                                },
                            ],
                            color: "grey darken-3",
                        },
                        {
                            title: "이혼사유 증거수집 및 보전",
                            txt: "이혼소송 단계",
                            solution: "이혼사유 증거는 초기에 선제적으로 확보하여야 합니다.",
                            solutionTxt: ["부정행위, 폭행, 폭언 등 이혼사유는 초기에 선제적으로 확보하는 것이 중요합니다.", "특히 부정행위는 이혼소송과 함께 진행될 간통남녀에 대한 상간소송에서의 승소도 함께 연결되므로 확실하게 물적 증거를 확보하는 것이 중요합니다."],
                            program: [
                                {
                                    title: "판심 법무법인 증거 확보 프로그램",
                                    txtDetail: [
                                        {
                                            title: "부정행위 증거확보",
                                            txt: ["부정행위를 인정하는 녹음을 확보합니다.", "부정행위와 관련된 카카오톡 메시지, 문자를 확보합니다.", "부정행위 상대방과의 데이트비용 지출 내역을 확보합니다.", "유흥업소 방문 내역 및 유흥업소 종사자와의 만남 증거를 확보합니다."],
                                        },
                                        {
                                            title: "폭행, 폭언 증거확보",
                                            txt: ["모욕, 명예훼손적 발언 녹음을 확보합니다.", "아이, 직계존속, 배우자에 대한 폭행 신고내역, 진단서, 상해 사진을 확보합니다.", "물건손괴 증거를 확보합니다."],
                                        },
                                        {
                                            title: "상간남녀의 집 또는 직장에 상간소송 소장을 송달시키며 소송을 시작합니다.",
                                            txt: ["생활비, 양육비 미지급 증거를 확보합니다.", "무단가출 증거를 확보합니다."],
                                        },
                                    ],
                                },
                            ],
                            color: "grey darken-3",
                        },
                        {
                            title: "재산 조회 및 재산 분석",
                            txt: "이혼소송 단계",
                            solution: "재산분할은 그 대상을 선정하는 것에서 승패가 갈립니다.",
                            solutionTxt: ["부부 일방이 혼인 전에 취득하여 소유하고 있던 재산이나, 혼인 중이라도 쌍방의 협력과는 관계없이 부부 일방이 상속, 증여 등에 의하여 취득한 이른바 특유재산은 원칙적으로 재산분할의 대상에서 제외됩니다.", "재산분할은 기여도를 산정하여 재산분할대상 재산 전체에 대하여 산정하는 방식으로 진행됩니다.", "따라서 재산분할대상이 선정되는 과정이 가장 중요하며, 재산분할분쟁 승패의 90%이상은 재산분할대상확정 달려있습니다."],
                            program: [
                                {
                                    title: "판심 법무법인 재산조회 프로그램",
                                    txt: ["재산명시 및 재산조회제도를 통해 은행, 보험사, 등기소, 국토부 등을 통해 상대방의 숨겨진 재산을 탐지합니다.", "상대방의 재산은닉을 방지하기 위하여 부동산 가압류, 가처분을 통해 부동산을 매각하거나 예금을 출금하고 타인에 증여하는 행위를 금지하여 재산을 확보합니다.", "우리측의 특유재산을 가려내어 재산분할대상에서 제외시킵니다."],
                                },
                            ],
                            color: "grey darken-3",
                        },
                        {
                            title: "양육권자 지정",
                            txt: "이혼소송 단계",
                            solution: "‘가사조사’를 미리 준비해야 승리합니다. 한번 해본 사람을 이길 수 있는 사람은 없습니다.",
                            solutionTxt: ["이혼소송 중 양육권 다툼이 있는 경우, 가사조사관들이 양육권판단에 기준이 되는 요소들을 조사하여 보고서를 작성합니다.", "이때 가사조사관이 작성한 보고서는 객관적인 보고서라는 점에서 크게 신뢰하므로 향후 양육자로서 선정되기 위해서 가사조사관들의 조사 기준들을 잘 숙지하고 준비하는 것이 중요합니다."],
                            program: [
                                {
                                    title: "판심 법무법인 가사조사 시뮬레이션",
                                    txt: ["가사조사 예상 질문리스트와 법률적으로 유리한 모법답안을 제공합니다.", "실제 가사조사와 유사한 환경에서 예상질문을 통한 시뮬레이션을 진행합니다.", "자체적으로 확보한 가사조사보고서 샘플을 활용하여 양육권자 지정에 필요한 부분을 확인하고, 위험요소를 제거합니다."],
                                },
                            ],
                            color: "grey darken-3",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.solution-item {
    &__inner {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            transition: 0.2s ease-out;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .solution-item:hover {
        .solution-item__inner {
            // background-color: rgba(210, 178, 155, .6);
            &::before {
                background: var(--v-primary-base);
                opacity: 0.6;
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "pt-0 overflow-hidden",
    scopedSlots: _vm._u([{
      key: "mainSectionHead",
      fn: function () {
        return [_c('v-img', {
          staticClass: "story-img w-100 mx-auto",
          attrs: {
            "data-aos": "fade-up",
            "src": "/images/main/story-img.jpg",
            "max-width": "1920",
            "aspect-ratio": 1920 / 500
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('tit-wrap-primary', {
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("S")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("UCCESS STORY")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("성공사례")];
      },
      proxy: true
    }, {
      key: "titRight",
      fn: function () {
        return [_c('btn-secondary', {
          staticClass: "w-100 v-btn--width-fixed",
          attrs: {
            "to": "/story/firm",
            "large": ""
          }
        }, [_vm._v("More View")])];
      },
      proxy: true
    }])
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, _vm._l(_vm.successes, function (success, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('story-item', {
      attrs: {
        "to": `/story/${success._id}`,
        "thumb": success.thumb,
        "large": ""
      },
      scopedSlots: _vm._u([{
        key: "date",
        fn: function () {
          return [_vm._v(_vm._s(_vm.$dayjs(success.createdAt).format("YYYY-MM-DD")))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(success.subject) + " ")])], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }